@import '../../global/mixins/media';

.custom_events_form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .mandatory_fields,
  .optional_fields {
    flex: 0 1 45%;
    margin: 1rem;
  }

  .submit_button {
    flex: 1 0 100%;
    text-align: center;
  }
}

.field {
  margin-bottom: 1rem;
}

.errorMsg {
  color: #ec0000;
  font-size: 0.8em;
  font-weight: 600;
}
.copy {
    color: white;
    margin-left: 2rem;
}

.footer {
    background-color: #0c0c0c;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    bottom: 0;
    width: 100%;
    text-align:center;
}
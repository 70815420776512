.button {
    color: white;
    background-color: #0c0c0c;
    border: 0;
    border-radius: 1.25rem;
    padding: 5px 15px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: .125rem;
    line-height: 1.625rem;
    transition: 0.3s linear;

    &:hover {
        background-color: #dff941;
        color: #0c0c0c;
    }
}
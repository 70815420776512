.card_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  >div {

    &:first-of-type {
      @media screen and (min-width: 1280px) {
        margin-left: 0;
      }
    }
  }
}

.cart {
  margin-bottom: 3rem;

  ul {
    list-style: none;
    padding-left: 0;

    li {
      line-height: 0.5em;

      h3 {
        margin-top: 2rem;
      }
    }
  }

  .systemMsg {
    font-size: 0.9em;
    color: #aeaeae;
  }
}
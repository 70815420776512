.main {
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 125px);
  width: calc(100% - 4rem);

  .wrapper {
    width: 100%;
    max-width: 1920px;
  }
}


// Styling Formik forms

form {
  label {
    font-size: 1.3em;
    font-weight: 600;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
  }

  input,
  select {
    margin-top: 0.5rem;
    width: 96%;
    min-width: 400px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    font-size: 1.2em;
    padding-left: 0.5rem;
    display: block;
  }

  button {
    margin-top: 1.5rem;
  }
}
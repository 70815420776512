.card {
    border-radius: 5px;
    background-color: #f2f5f7;
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
    transition: 0.1s linear transform;
    max-width: calc(25% - 2rem);

    @media screen and (max-width:800px) {
        max-width: 100%;
    }


    @media screen and (max-width:1280px) {
        max-width: calc(50% - 2rem);
    }


    h2 {
        text-align: center;
        margin: 1rem auto;
        position: relative;
    }

    img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    a {
        color: #0C0C0C;
        text-decoration: none;
        transition: 0.1s linear color;

        &:hover {
            color: #0C0C0C;
        }

    }

    &:hover {
        transform: scale(1.01);
    }
}

.card_img {
    max-width: 100%;
    width: 600px;
    height: 400px;
    object-fit: cover;
}
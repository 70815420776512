.card {
    border-radius: 5px;
    background-color: #f2f5f7;
    max-width: calc(25% - 2rem);
    min-width: 200px;
    margin: 1rem auto;
    padding: 0 2rem 2rem 2rem;
    min-height: 300px;

    @media screen and (max-width: 1280px) {
        max-width: calc(50% - 2rem);
    }

    @media screen and (max-width: 800px) {
        max-width: 100%;
    }

    h3 {
        margin-top: 1rem;
    }

    img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

.img_container {
    margin-left: -2rem;
    margin-right: -2rem;
    display: flex;
    justify-content: center;
}

.card_img {
    max-width: 100%;
    width: 600px;
    height: 450px;
    object-fit: cover;
}
.signup_form {
    margin-top: 2rem;
    width: 100%;
}

.field {
    margin-bottom: 1rem;
}

.errorMsg {
    color: #ec0000;
    font-size: 0.8em;
    font-weight: 600;
}
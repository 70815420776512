.navbar {
  background-color: #0c0c0c;
  margin-bottom: 2rem;
}

.navContainer {
  @media (min-width:1400px) {
    max-width: 1920px !important;
  }
}

.account {
  color: white;
  list-style: none;
  float: right;
  display: inline;
  margin: 0 2rem 0 0;
  cursor: pointer;

  img {
    margin-right: 0.7rem;
    position: relative;
    top: -0.2rem;
  }
}

.dropdown {
  font-size: 0.7em;
  margin-left: 0.4rem;
}